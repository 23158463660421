import { render, staticRenderFns } from "./delivery_drop_off_locations.vue?vue&type=template&id=5415f388&scoped=true"
import script from "./delivery_drop_off_locations.vue?vue&type=script&lang=js"
export * from "./delivery_drop_off_locations.vue?vue&type=script&lang=js"
import style0 from "./delivery_drop_off_locations.vue?vue&type=style&index=0&id=5415f388&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5415f388",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src4028959781/src/clients/ap3/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5415f388')) {
      api.createRecord('5415f388', component.options)
    } else {
      api.reload('5415f388', component.options)
    }
    module.hot.accept("./delivery_drop_off_locations.vue?vue&type=template&id=5415f388&scoped=true", function () {
      api.rerender('5415f388', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sites/delivery_drop_off_locations.vue"
export default component.exports